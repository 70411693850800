<template>
  <div>
    <div class="header">
      <div class="header_top">
        <span style="margin: 0">当前位置：</span>
        <span class="center">批量合同</span>
        <img src="../../../assets/right.png"
             alt />
        <span class="center">新建批量导入</span>
      </div>
      <!-- <div>
        <el-upload
          style="text-align: left;"
          class="upload-demo"
          action
          :multiple="false"
          :limit="1"
          :auto-upload="false"
          accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          :on-change="importExcel"
          :file-list="uploadFiles"
        >
          <el-button size="small" type="primary">点击上传</el-button>
        </el-upload>
      </div>-->
    </div>
    <el-card>
      <Divider :title="'基本信息'"></Divider>
      <el-form ref="ruleForm"
               :model="ruleForm"
               label-width="100px"
               :rules="rules">
        <div>
          <el-row type="flex"
                  class="row-bg"
                  justify="space-between"
                  style="margin: 10px 0;">
            <el-col :span="8">
              <el-form-item label="用工企业"
                            prop="workEnterpriseCode">
                <el-select remote
                           reserve-keyword
                           :remote-method="query"
                           @change="getCity"
                           v-model="ruleForm.workEnterpriseCode"
                           filterable
                           placeholder="请输入企业名称"
                           style="margin-right:10px;width:100%">
                  <el-option v-for="item in eList"
                             :disabled="!item.enable"
                             :key="item.id"
                             :label="item.name"
                             :value="item.code"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="企业区域"
                            prop="workEnterpriseAreaCode">
                <el-select v-model="ruleForm.workEnterpriseAreaCode"
                           @change="querycity"
                           style="margin-right:10px;width:100%"
                           filterable
                           :placeholder="'请输入企业区域'">
                  <el-option-group v-for="group in areaSelectList"
                                   :key="group.provinceName"
                                   :label="group.provinceName">
                    <span style="color:red"
                          slot="label">{{ group.provinceName }}</span>
                    <el-option v-for="item in group.citys"
                               :key="item.id"
                               :label="item.cityName"
                               :value="item.code"></el-option>
                  </el-option-group>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="企业门店"
                            prop="workEnterpriseStoreCode">
                <el-select remote
                           reserve-keyword
                           v-model="ruleForm.workEnterpriseStoreCode"
                           filterable
                           placeholder="请输入企业门店"
                           style="margin-right: 10px;width:100%">
                  <el-option v-for="item in storeList"
                             :key="item.id"
                             :label="item.name"
                             :value="item.code"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex"
                  class="row-bg"
                  justify="space-between"
                  style="margin: 10px 0;">
            <el-col :span="8">
              <el-form-item label="合同模板"
                            prop="templateCode">
                <el-select remote
                           reserve-keyword
                           :remote-method="query"
                           v-model="ruleForm.templateCode"
                           @change="templateChange"
                           v-selectLoadMore="selectLoadMore"
                           filterable
                           placeholder="请输入合同模板"
                           style="margin-right:10px;width:100%">
                  <el-option v-for="item in contractTemplateList"
                             :key="item.id"
                             :label="item.name"
                             :value="item.code"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="合同类型"
                            prop="type">
                <el-input v-model="ruleForm.type"
                          placeholder="请输入合同类型"
                          disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="导入名称"
                            prop="name">
                <el-input v-model="ruleForm.name"
                          placeholder="请输入批量导入名称"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex"
                  class="row-bg"
                  justify="flex-start"
                  style="margin: 10px 0;">
            <el-col :span="8">
              <el-form-item label="甲方签署人"
                            prop="signerUserCode">
                <el-select v-model="ruleForm.signerUserCode"
                           clearable
                           placeholder="请选择甲方签署人"
                           style="margin-right:10px;width:100%">
                  <el-option v-for="(item, index) in singerList"
                             :key="index"
                             :label="item.user.name"
                             :value="item.user.code"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="批量导入描述"
                            prop="remark">
                <el-input type="textarea"
                          v-model="ruleForm.remark"
                          :autosize="{ minRows: 2, maxRows: 4}"
                          placeholder="请输入批量导入描述"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-card v-if="templateShow"
                   style="margin:10px 0">
            <div style="flex-grow: 1">
              <Divider :title="'模板参数配置'"
                       :tag="'请上传参数配置文件后指定模板参数对应的表头字段'"
                       style="margin:10px 0"></Divider>
              <div class="page_border">
                <el-form-item v-for="(item, i) in templateArr"
                              :key="i"
                              style="width:25%;">
                  <div slot="label">
                    <div class="label_class">
                      <span style="width: 100px;">{{ item.label}}</span>
                    </div>
                  </div>
                  <el-select v-model="item.name"
                             filterable
                             placeholder="请选择字段类目"
                             style="width: 100%"
                             @change="change">
                    <el-option v-for="(item, index) in List"
                               :key="index"
                               :disabled="checkboxGroup.includes(item)"
                               :label="item"
                               :value="item"></el-option>
                  </el-select>
                </el-form-item>
              </div>
            </div>
          </el-card>
        </div>
      </el-form>
      <Divider :title="'参数配置信息'"
               :tag="fileName">
        <template slot="right">
          <div>
            <el-upload style="text-align: left;"
                       class="upload-demo"
                       action
                       :multiple="false"
                       :limit="1"
                       :auto-upload="false"
                       accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                       :on-change="importExcel"
                       :file-list="uploadFiles">
              <el-button size="small"
                         type="text">点击上传</el-button>
            </el-upload>
          </div>
        </template>
      </Divider>
      <el-table :data="tableData"
                border
                style="width: 100%;margin:10px 0"
                v-if="show">
        <el-table-column v-for="(item, index) in List"
                         :key="index"
                         :label="item">
          <template slot-scope="scope">
            <span v-html="dateFormat(scope.row[item])"></span>
          </template>
        </el-table-column>
        <el-table-column label="人员查询结果">
          <template slot-scope="scope">
            <p>{{scope.row.msg}}</p>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <!-- <template slot-scope="scope">
          <el-popconfirm title="确定对张三印章授权？" @confirm="confirmAuthorization(scope.row)">
            <el-button type="text" slot="reference">印章授权</el-button>
          </el-popconfirm>-->
          <template slot-scope="scope"
                    v-if="scope.row.msg!='该用户没有数据!'">
            <el-button type="text"
                       @click="del(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="dialog-footer">
        <el-button type="primary"
                   @click="submitForm('ruleForm')">校 验</el-button>
        <el-button type="primary"
                   :disabled="isShow"
                   @click="getAdd">提 交</el-button>
        <el-button @click="closeDialog">取 消</el-button>
      </div>
    </el-card>
  </div>
</template>
<script>
import XLSX from 'xlsx';
import { api } from '/src/api/base';
import moment from 'moment';
import * as _ from 'lodash';
import { ContractTemplate } from '../contractParse';
export const workEnterpriseList = api()('work_enterprise.list.json');
export const contractSingerList = api()('contractSinger.list.json')
// 企业模板详情
export const contractTemplateEnterpriseInfo = api()('contractTemplate.enterprise.info.json');
// 获取企业区域列表
export const workEnterpriseAreaList = api()('work_enterprise_area.list.json');
// 获取企业区域门店
export const workEnterpriseStoreList = api()('work_enterprise_store.list.json');
// 企业名称获取企业区域列表
export const workEnterpriseAreaContainArea = api()('work_enterprise_area.containArea.json');
// 企业区域下模板列表
export const contractTemplateEnterpriseList = api('')(
  'contractTemplate.enterprise.list.json'
);
export const contractContractBatchAddCheck = api('')(
  'contract.contractBatch.check.json'
);
export const contractContractBatchAdd = api('')(
  'contract.contractBatch.add.json'
);

export default {

  components: {
    Divider: () => import('../../../components/divider.vue'),
  },
  data () {
    return {
      ruleForm: {
        workEnterpriseCode: '',
        workEnterpriseAreaCode: '',
        workEnterpriseStoreCode: '',
        templateCode: '',
        type: '',
        name: '',
        signerUserCode: '',
        remark: ''
      },
      rules: {
        workEnterpriseCode: [
          {
            required: true,
            message: '请选择企业',
            trigger: 'change',
          },
        ],
        workEnterpriseAreaCode: [
          {
            required: true,
            message: '请选择区域',
            trigger: 'change',
          },
        ],
        workEnterpriseStoreCode: [
          {
            required: true,
            message: '请选择门店',
            trigger: 'change',
          },
        ],
        templateCode: [
          {
            required: true,
            message: '请选择合同模板',
            trigger: 'change',
          }
        ],
        name: [
          {
            required: true,
            message: '请输入批量导入名称',
            trigger: 'change',
          }
        ],
        signerUserCode: [
          {
            required: true,
            message: '请选择甲方签署人',
            trigger: 'change',
          }
        ]
      },
      eList: [],
      areaSelectList: [],
      storeList: [],
      singerList: [],
      contractTemplateList: [],
      totalPage: '',
      pageNumber: 1,
      pageSize: 10,
      templateShow: false,
      templeteObject: null,
      uploadFiles: [],
      List: [],
      fileName: '',
      checkboxGroup: [],
      tableData: [],
      templateArr: [],
      data: [],
      isShow: true,
      type: '',
      show: false
    }
  },
  created () {
    this.enterpriseList()

  },
  methods: {
    dateFormat (row) {

      if (typeof (row) != 'object') {
        return row
      }
      return moment(row).format('YYYY-MM-DD')
    },

    async change () {
      this.checkboxGroup = this.templateArr.filter(it => this.List.map(i => i).includes(it.name)).map(_it => (_it.name))
    },
    enterpriseList (val) {
      const params = {
        keywords: val,
        pageNumber: 1,
          pageSize: 1000 
      };
      workEnterpriseList(params).then(res => {
        this.eList = res.list
      });
    },
    query (val) {
      this.pageNumber = 1;
      this.enterpriseList(val);
    },
    getCity (val) {

      const params = {
        workEnterpriseCode: val
      };
      workEnterpriseAreaContainArea(params).then(res => {
        this.areaSelectList = res
        this.ruleForm.workEnterpriseAreaCode = ''
        this.storeList = []
        this.ruleForm.workEnterpriseStoreCode = ''
        this.contractTemplateList = []
        this.ruleForm.templateCode = ''
        this.ruleForm.type = ''
        this.ruleForm.name = ''
        this.templeteObject = null;
        this.templateArr = []
        this.templateShow = false
        this.show = false
        this.tableData = []
        this.fileName = ''
      });



    },
    querycity (val) {
      workEnterpriseStoreList({
        workEnterpriseAreaCode: val,
        pageNumber: 1,
        pageSize: 1000
      }).then(res => {
        this.storeList = res.list
        this.ruleForm.workEnterpriseStoreCode = ''
        this.ruleForm.templateCode = ''
        this.ruleForm.type = ''
        this.ruleForm.name = ''
        this.templeteObject = null;
        this.templateArr = []
        this.templateShow = false
        this.show = false
        this.tableData = []
        this.fileName = ''
        this.getTemplate()

      });
    },
    getTemplate () {
      contractTemplateEnterpriseList({
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        workEnterpriseCode: this.ruleForm.workEnterpriseCode,
        workEnterpriseArea: this.ruleForm.workEnterpriseAreaCode,
      }).then(res => {
        this.totalPage = Math.ceil(res.total / 10);

        this.contractTemplateList = this.contractTemplateList.concat(res.list)
      });
    },
    async selectLoadMore () {
      this.pageNumber = this.pageNumber + 1;
      if (this.pageNumber > this.totalPage) return;
      await this.getTemplate(); // 请求接口
    },


    async getSingerList () {

      const params = {
        authStatus: '1',
        pageSize: 1000,
        pageNumber: 1,
      }
      const res = await contractSingerList(params)

      this.singerList = res.list
    },
    async templateChange (val) {
      const result = await contractTemplateEnterpriseInfo({ code: val });

      this.type = result.type
      switch (Number(result.type)) {
        case 0:
          this.ruleForm.type = '入职合同'
          break;
        case 1:
          this.ruleForm.type = '入职合同'
          break;
        default:
          this.ruleForm.type = '其他'
      }
      this.ruleForm.name = `${result.name}的批量导入`

      this.templeteObject = new ContractTemplate(result);
      this.templateArr = this.templeteObject.fields.map(it => ({
        label: it.label,
        name: ''
      }))
      this.fileName = ''
      this.show = false
      this.List = []
      this.tableData = []
      this.getSingerList()


      this.templateShow = true
    },
    // 导入表格
    async importExcel (param) {
      this.show = true
      this.List = []
      this.uploadFiles = [];
      this.templateArr = this.templateArr.map(it => ({
        label: it.label,
        name: ''
      }))
      this.checkboxGroup = []

      this.fileName = param.name;
      const arr = await this.getExcelHeaders(param)
      this.file2Xce(param).then(item => {
        if (item && item.length > 0) {
          if (item[0].sheet.length === 0) {
            return this.$message.error('传入表单无数据！');
          }
          if (item[0] && item[0].sheet && item[0].sheet.length) {
            // 校验传入表格字段
            console.log(item[0].sheet);
            const valid = item[0].sheet[0].every(
              item =>
                item != '身份证号'
            );
            console.log(valid);
            if (valid) {
              return this.$message.error('请传入正确表格!');
            }

            this.tableData = _.drop(item[0].sheet, 1).map((it) => {
              return _.zipObject(item[0].sheet[0], it);
            }); // 把数据塞到表格预览
            this.tableData = this.tableData.filter(it => it['身份证号'] != undefined)
            console.log(this.tableData);

          }
        }
      });

      if (_.uniq(arr).filter(n => n).length != arr.length) {
        this.$message({
          message: '警告哦，表格中不能有重复表头或者空表头',
          type: 'warning'
        });
      }
      else {

        arr.forEach((item) => {
          this.List.push(item)
        })
      }

    },
    file2Xce (file) {

      return new Promise(function (resolve) {
        const reader = new FileReader();
        reader.onload = function (e) {

          const data = e.target.result;

          this.wb = XLSX.read(data, {
            type: 'binary',
            cellDates: true,

          });

          const result = [];

          this.wb.SheetNames.forEach(sheetName => {
            result.push({
              sheetName: sheetName,
              sheet: XLSX.utils.sheet_to_json(this.wb.Sheets[sheetName], { header: 1, raw: false }).map(n => n.map(k => { const [m, d, y] = (k + '').split('/'); return y ? `20${y}年${m}月${d}日` : k }))
            });
          });
          console.log(result, 22222);
          resolve(result);
        };
        reader.readAsBinaryString(file.raw);
      });
    },
    convertExcelDateFormat: function (row, columnName) {//日期转换
      var date = row[columnName]
      if (date === undefined || date === null || date === '') {
        return null;
      }
      //非时间格式问题  返回Invalid date
      let retFormat = moment(date).format('YYYY-MM-DD');
      if (retFormat === 'Invalid date') {
        return retFormat;
      }
      return moment(date).add(1, 'days').format('YYYY-MM-DD')
    },

    getExcelHeaders (file) {
      const getHeaderRow = (sheet) => {
        const headers = []

        const range = XLSX.utils.decode_range(sheet['!ref'])
        let C = range.s.c
        for (C = range.s.c; C <= range.e.c; ++C) {
          const cell = sheet[XLSX.utils.encode_cell({ c: C, r: 0 })]
          if (cell && cell.t) {
            headers.push(XLSX.utils.format_cell(cell))
          }
        }
        return headers
      }
      return new Promise(function (resolve) {
        const reader = new FileReader();
        reader.onload = function (e) {
          const data = e.target.result;

          this.wb = XLSX.read(data, {
            type: 'binary',
          });

          resolve(getHeaderRow(this.wb.Sheets.Sheet1 || this.wb.Sheets.sheet1));
        };
        reader.readAsBinaryString(file.raw);
      });

    },
    submitForm (formName) {

      if (this.templateArr.every(it => it.name != '')) {


        if (this.tableData.length != 0) {
          this.$refs[formName].validate(valid => {
            if (valid) {
              var c = {}
              this.templateArr.forEach(n => c[n.name] = n.label)

              var f = this.tableData.map(n => {
                var t = {};
                Object.keys(n).forEach(m => {
                  if (c[m]) {
                    if (typeof (n[m]) != 'object') {
                      return t[c[m]] = n[m]
                    }
                    return t[c[m]] = moment(n[m]).format('YYYY-MM-DD')

                  }
                })
                return t
              })
              this.data = f
              if (!this.data.every(n => n['身份证号'] !== undefined)) {
                this.$confirm('合同模板参数没有身份证号字段，请前往修改企业合同模板！', '提示', {
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
                  type: 'error'
                }).then(() => {
                  this.$router.push({
                    path: '/system/enterpriseContract/List'
                  })

                }).catch(() => {
                  this.$message({
                    type: 'info',
                    message: '已取消'
                  });
                });

              }
              else {
                const params = {
                  data: this.data,
                  templateCode: this.ruleForm.templateCode,
                  signerUserCode: this.ruleForm.signerUserCode,
                  workEnterpriseStoreCode: this.ruleForm.workEnterpriseStoreCode
                };
                contractContractBatchAddCheck(params).then((res) => {

                  const a = res.map(it => ({
                    msg: it.msg
                  }))
                  this.tableData = this.tableData.map((i, index) => {
                    return {
                      ...i,
                      msg: a[index].msg
                    }
                  })
                  this.isShow = false

                });
              }
            }
          })
        }
        else {
          this.$message.info('没有添加人员')
        }
      }
      else {
        this.$message.info('请填写合同模板参数配置')
      }
    },
    getAdd () {
      this.isShow = true
      const params = {
        data: this.data,
        name: this.ruleForm.name,
        type: this.type,
        desc: this.ruleForm.remark,
        workEnterpriseCode: this.ruleForm.workEnterpriseCode,
        workEnterpriseAreaCode: this.ruleForm.workEnterpriseAreaCode,
        templateCode: this.ruleForm.templateCode,
        signerUserCode: this.ruleForm.signerUserCode,
        workEnterpriseStoreCode: this.ruleForm.workEnterpriseStoreCode
      };
      contractContractBatchAdd(params).then(() => {
        this.$message.success('添加成功')
        this.closeDialog()
      });

    },
    del (row) {
      this.tableData = this.tableData.filter(it => it['身份证号'] != row['身份证号'])

    },
    closeDialog () {
      window.history.go(-1);
    }

  },
}
</script>
<style lang="scss" scoped>
.page_border {
  display: flex;
  flex-wrap: wrap;
}
.dialog-footer {
  width: 100%;

  margin: 10px 0;

  span {
    font-size: 14px;
  }
}
</style>